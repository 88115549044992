import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import CampusTitle from "../components/sections/Title"
import About from "../components/sections/About"

const CampusContent = styled.div`
  padding: 0 0 6rem;
`

const Khouribga = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
    cluster,
    playground,
    cafeteria,
    meetings,
    findUs,
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "khouribga/banner.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cluster: file(relativePath: { eq: "khouribga/cluster.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      playground: file(relativePath: { eq: "khouribga/playground.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cafeteria: file(relativePath: { eq: "khouribga/cafeteria.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      meetings: file(relativePath: { eq: "khouribga/meetings.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      findUs: file(relativePath: { eq: "khouribga/findUs.jpeg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <CampusContent>
        <Seo
          title={formatMessage({
            id: "sections.campus.khouribga.header.title",
          })}
        />
        <CampusTitle
          title={formatMessage({
            id: "sections.campus.khouribga.header.title",
          })}
          fixed={fixed}
        >
          {formatMessage({ id: "sections.campus.khouribga.header.paragraph" })
            .split("\n")
            .map((paragraph, key) => (
              <p key={key}>{paragraph}</p>
            ))}
        </CampusTitle>
        <About
          fixed={cluster.childImageSharp.fixed}
          title={formatMessage({
            id: "sections.campus.khouribga.sections.cluster.title",
          })}
          paragraph={formatMessage({
            id: "sections.campus.khouribga.sections.cluster.paragraph",
          })}
        />
        <About
          fixed={playground.childImageSharp.fixed}
          reverse
          title={formatMessage({
            id: "sections.campus.khouribga.sections.playground.title",
          })}
          paragraph={formatMessage({
            id: "sections.campus.khouribga.sections.playground.paragraph",
          })}
        />
        <About
          fixed={cafeteria.childImageSharp.fixed}
          title={formatMessage({
            id: "sections.campus.khouribga.sections.cafeteria.title",
          })}
          paragraph={formatMessage({
            id: "sections.campus.khouribga.sections.cafeteria.paragraph",
          })}
        />
        <About
          fixed={meetings.childImageSharp.fixed}
          reverse
          title={formatMessage({
            id: "sections.campus.khouribga.sections.meetings.title",
          })}
          paragraph={formatMessage({
            id: "sections.campus.khouribga.sections.meetings.paragraph",
          })}
        />
        <About
          fixed={findUs.childImageSharp.fixed}
          title={formatMessage({
            id: "sections.campus.khouribga.sections.findUs.title",
          })}
          paragraph={formatMessage({
            id: "sections.campus.khouribga.sections.findUs.paragraph",
          })}
        />
      </CampusContent>
    </DefaultLayout>
  )
}

export default Khouribga
