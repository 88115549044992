import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import CampusTitle from "../components/sections/Title"
import About from "../components/sections/About"

import SectionTitle from "../components/SectionTitle"
import Container from "../components/Container"
import styled from "styled-components"

const TitleSection = styled.div`
  margin: 10rem 0;
  text-align: center;
`

const EndSpace = styled.div`
  padding-bottom: 6rem;
`

const Concept = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
    peerLearning,
    peerAssessment,
    peerPedagogy,
    gamification,
    threeYears,
    improving,
    time,
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "concept/banner.jpeg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      peerLearning: file(relativePath: { eq: "concept/peer-learning.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      peerAssessment: file(
        relativePath: { eq: "concept/peer-assessment.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      peerPedagogy: file(relativePath: { eq: "concept/peer.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gamification: file(relativePath: { eq: "concept/gamification.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      threeYears: file(relativePath: { eq: "concept/3years.jpeg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      improving: file(relativePath: { eq: "concept/improving.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      time: file(relativePath: { eq: "concept/time.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <Seo
        title={formatMessage({
          id: "sections.concept.header.title",
        })}
      />
      <CampusTitle
        title={formatMessage({
          id: "sections.concept.header.title",
        })}
        fixed={fixed}
      >
        {formatMessage({
          id: "sections.concept.header.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      </CampusTitle>
      <About
        fixed={peerLearning.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.concept.sections.peerLearning.title",
        })}
        paragraph={formatMessage({
          id: "sections.concept.sections.peerLearning.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      />
      <About
        fixed={peerAssessment.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.concept.sections.peerAssessment.title",
        })}
        paragraph={formatMessage({
          id: "sections.concept.sections.peerAssessment.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
        reverse
      />
      <About
        fixed={peerPedagogy.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.concept.sections.peerPedagogy.title",
        })}
        paragraph={formatMessage({
          id: "sections.concept.sections.peerPedagogy.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      />
      <Container>
        <TitleSection>
          <SectionTitle>
            {formatMessage({
              id: "sections.concept.sections.gamification.title",
            })}
          </SectionTitle>
          <p>
            {formatMessage({
              id: "sections.concept.sections.gamification.paragraph",
            })}
          </p>
        </TitleSection>
      </Container>
      <About
        fixed={gamification.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.concept.sections.gamificationGuide.title",
        })}
        paragraph={formatMessage({
          id: "sections.concept.sections.gamificationGuide.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
        reverse
      />
      <Container>
        <TitleSection>
          <SectionTitle>
            {formatMessage({
              id: "sections.concept.sections.wheneverIWant.title",
            })}
          </SectionTitle>
          <p>
            {formatMessage({
              id: "sections.concept.sections.wheneverIWant.paragraph",
            })}
          </p>
        </TitleSection>
      </Container>
      <About
        fixed={threeYears.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.concept.sections.threeYears.title",
        })}
        paragraph={formatMessage({
          id: "sections.concept.sections.threeYears.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      />
      <About
        fixed={improving.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.concept.sections.improving.title",
        })}
        paragraph={formatMessage({
          id: "sections.concept.sections.improving.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
        reverse
      />
      <About
        fixed={time.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.concept.sections.time.title",
        })}
        paragraph={formatMessage({
          id: "sections.concept.sections.time.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      />
      <EndSpace />
    </DefaultLayout>
  )
}

export default Concept
