import React, { useState } from "react"
import styled from "styled-components"
import { RiGamepadLine } from "react-icons/ri"
import { FiUserCheck } from "react-icons/fi"
import { FaSwimmer } from "react-icons/fa"
import { MdDesktopMac } from "react-icons/md"
import ModalVideo from "react-modal-video"
import { useIntl, Link } from "gatsby-plugin-intl"

import Container from "../Container"
import SectionTitle from "../SectionTitle"

import "../../assets/css/modal-video.css"

const StyledDna = styled.div`
  padding: 6rem 0;
`

const Boxes = styled.div`
  display: flex;
  margin: 6rem -1.5rem 0;
  flex-direction: column;
`

const BoxIcon = styled.div`
  color: #a3de83;
  font-size: 6rem;
  display: flex;
  margin-top: -6rem;
  transition: 300ms ease-in-out;
`

const Box = styled(Link)`
  text-decoration: none;
  color: #111;
  margin: 3rem 0;
  background: #f9f9f9;
  padding: 3rem 3rem 1.5rem;
  border-radius: 0.5rem;
  flex: 1;
  border-radius: 0.5rem;
  border-bottom: 0.5rem solid transparent;
  transition: 300ms ease-in-out;
  h3 {
    margin: 1.5rem 0;
    font-size: 2.4rem;
    span {
      color: #a3de83;
    }
  }
  p {
    margin: 1.5rem 0;
    line-height: 1.8;
  }
  &:hover {
    background: #fff;
    transform: scale(1.05);
    border-color: #a3de83;
    ${BoxIcon} {
      color: #111;
    }
  }
`

const Dna = () => {
  const [modal, setModal] = useState(false)
  const { formatMessage } = useIntl()

  return (
    <StyledDna>
      <ModalVideo
        channel="youtube"
        isOpen={modal}
        videoId="kveqI_sZdps"
        onClose={() => setModal(false)}
      />
      <Container>
        <SectionTitle>
          {formatMessage({ id: "sections.road.title" })}
        </SectionTitle>
        <Boxes>
          <Box to="/legal-notice-and-general-conditions">
            <BoxIcon>
              <RiGamepadLine />
            </BoxIcon>
            <h3>
              <span>#1 </span>-{" "}
              {formatMessage({ id: "sections.road.steps.onlineTest.title" })}
            </h3>
            <p>
              {formatMessage({
                id: "sections.road.steps.onlineTest.paragraph",
              })}
            </p>
          </Box>
          <Box to="/steps">
            <BoxIcon>
              <FiUserCheck />
            </BoxIcon>
            <h3>
              <span>#2 </span>-{" "}
              {formatMessage({ id: "sections.road.steps.checkin.title" })}
            </h3>
            <p>
              {formatMessage({ id: "sections.road.steps.checkin.paragraph" })}
            </p>
          </Box>
          <Box to="/the-pool">
            <BoxIcon>
              <FaSwimmer />
            </BoxIcon>
            <h3>
              <span>#3 </span>-{" "}
              {formatMessage({ id: "sections.road.steps.pool.title" })}
            </h3>
            <p>{formatMessage({ id: "sections.road.steps.pool.paragraph" })}</p>
          </Box>
          <Box to="/professional-insertion">
            <BoxIcon>
              <MdDesktopMac />
            </BoxIcon>
            <h3>
              <span>#4 </span>-{" "}
              {formatMessage({ id: "sections.road.steps.journey.title" })}
            </h3>
            <p>
              {formatMessage({ id: "sections.road.steps.journey.paragraph" })}
            </p>
          </Box>
        </Boxes>
      </Container>
    </StyledDna>
  )
}

export default Dna
