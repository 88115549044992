import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import CampusTitle from "../components/sections/Title"
import Container from "../components/Container"
import FaqQuestion from "../components/FaqQuestion"

const EndSpace = styled.div`
  padding-bottom: 6rem;
`

const Faq = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "faq/banner.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <Seo
        title={formatMessage({
          id: "sections.faq.header.title",
        })}
      />
      <CampusTitle
        title={formatMessage({
          id: "sections.faq.header.title",
        })}
        fixed={fixed}
      >
        {formatMessage({ id: "sections.faq.header.paragraph" })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      </CampusTitle>
      <Container>
        <EndSpace />
        {new Array(8).fill(null).map((_, i) => (
          <FaqQuestion
            key={i}
            title={formatMessage({
              id: `sections.faq.list.${i}.q`,
            })}
          >
            {formatMessage({
              id: `sections.faq.list.${i}.a`,
            })
              .split("\n")
              .map((paragraph, i) => (
                <p key={i}>{paragraph}</p>
              ))}
          </FaqQuestion>
        ))}
      </Container>
      <EndSpace />
    </DefaultLayout>
  )
}

export default Faq
