import React from "react"
import styled from "styled-components"
import { Link, useIntl } from "gatsby-plugin-intl"
import { FiFacebook, FiTwitter, FiInstagram, FiYoutube } from "react-icons/fi"

import Container from "./Container"

import Divider from "../assets/images/divider.svg"

const StyledFooter = styled.div`
  padding: 15rem 0 0;
  background: #111;
  color: #fff;
  position: relative;
  h2 {
    margin: 0 0 3rem;
    font-size: 3rem;
  }
`

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${1280 / 16}rem) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledDivider = styled(Divider)`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
`

const ApplyButton = styled(Link)`
  text-decoration: none;
  color: #111;
  background: #a3de83;
  padding: 1rem 3rem;
  display: inline-block;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
  &:hover {
    background: #fff;
  }
`

const FooterTail = styled.div`
  padding: 1.5rem 0;
  margin-top: 12rem;
  background: #222;
`

const FooterTailContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  @media (max-width: ${1280 / 16}rem) {
    margin-top: 6rem;
  }
`

const SocialIcon = styled.a`
  color: #fff;
  text-decoration: none;
  margin-left: 1.5rem;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222;
  transition: 150ms ease-in-out;
  &:hover {
    background: #333;
  }
`

const Footer = () => {
  const { formatMessage } = useIntl()

  return (
    <StyledFooter>
      <StyledDivider />
      <Container>
        <FooterContent>
          <div>
            <h2>{formatMessage({ id: "sections.footer.title" })}</h2>
            <ApplyButton to="/legal-notice-and-general-conditions">
              {formatMessage({ id: `navbar.applyNow` })}
            </ApplyButton>
          </div>
          <div>
            <SocialMedia>
              {formatMessage({ id: "sections.footer.findUs" })}
              <SocialIcon href="https://www.facebook.com/1337FutureIsLoading">
                <FiFacebook />
              </SocialIcon>
              <SocialIcon href="https://twitter.com/1337FIL">
                <FiTwitter />
              </SocialIcon>
              <SocialIcon href="https://www.instagram.com/1337_FutureIsLoading">
                <FiInstagram />
              </SocialIcon>
              <SocialIcon href="https://www.youtube.com/c/1337_FutureIsLoading">
                <FiYoutube />
              </SocialIcon>
            </SocialMedia>
          </div>
        </FooterContent>
      </Container>
      <FooterTail>
        <Container>
          <FooterTailContent>
            <p>© 2021 | {formatMessage({ id: `sections.footer.tailText` })}</p>
            <p></p>
          </FooterTailContent>
        </Container>
      </FooterTail>
    </StyledFooter>
  )
}

export default Footer
