import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import Img from "gatsby-image"
import Typewriter from "typewriter-effect"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"
import Particles from "react-particles-js"

import Divider from "../../assets/images/divider.svg"

const StyledBanner = styled.section`
  position: relative;
  height: 90vh;
  overflow: hidden;
`

const BannerText = styled.h1`
  margin: 0;
  padding: 0 1.5rem;
  position: absolute;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.25);
  font-size: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: ${1280 / 16}rem) {
    font-size: 3rem;
  }
`

const StyledImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
`

const StyledDivider = styled(Divider)`
  position: absolute;
  left: 0;
  bottom: -1px;
`

const StyledParallax = styled(Parallax)`
  height: 100%;
  .parallax-inner {
    height: 100%;
  }
`

const Banner = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "banner2.jpg" }) {
        childImageSharp {
          fixed(
            width: 1500
            duotone: { highlight: "#ffffff", shadow: "#000000", opacity: 50 }
          ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <ParallaxProvider>
      <StyledBanner>
        <StyledParallax y={["-34%", "30%"]}>
          <Particles
            height="100%"
            width="100%"
            style={{
              position: "absolute",
              zIndex: 1,
            }}
            params={{
              particles: {
                number: {
                  value: 100,
                  density: {
                    enable: false,
                  },
                },
                size: {
                  value: 3,
                  random: true,
                  anim: {
                    speed: 4,
                    size_min: 0.3,
                  },
                },
                line_linked: {
                  enable: false,
                },
                move: {
                  random: true,
                  speed: 1,
                  direction: "top",
                  out_mode: "out",
                },
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "bubble",
                  },
                  onclick: {
                    enable: true,
                    mode: "repulse",
                  },
                },
                modes: {
                  bubble: {
                    distance: 250,
                    duration: 2,
                    size: 0,
                    opacity: 0,
                  },
                  repulse: {
                    distance: 400,
                    duration: 4,
                  },
                },
              },
            }}
          />
          <StyledImg
            fixed={fixed}
            style={{ width: "100%", height: "110%" }}
            draggable={false}
          />
        </StyledParallax>

        <BannerText>
          <Typewriter
            options={{
              strings: formatMessage({
                id: "sections.banner.titles",
              }).split(","),
              autoStart: true,
              loop: true,
            }}
          />
        </BannerText>
        <StyledDivider />
      </StyledBanner>
    </ParallaxProvider>
  )
}

export default Banner
