import React from "react"
import styled from "styled-components"

import TitleDivider from "../assets/images/title-divider.svg"

const TitleWrapper = styled.div`
  margin: 3rem 0;
  ${({ notCenter }) => !notCenter && "text-align: center;"}
  color: ${({ dark }) => (dark ? "#fff" : "#111")};
  h2 {
    margin: 0;
    font-size: ${({ small }) => (small ? "2.8rem" : "3.6rem")};
  }
`

const SectionTitle = ({ children, dark, notCenter, small }) => {
  return (
    <TitleWrapper dark={dark} notCenter={notCenter} small={small}>
      <h2>{children}</h2>
      <TitleDivider />
    </TitleWrapper>
  )
}

export default SectionTitle
