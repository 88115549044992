import React from "react"
import styled from "styled-components"

const StyledFeature = styled.div`
  width: 33.3333%;
  padding: 1.5rem;
  @media (max-width: ${1280 / 16}rem) {
    width: 50%;
  }
  @media (max-width: ${900 / 16}rem) {
    width: 100%;
  }
`

const BoxIcon = styled.div`
  color: #a3de83;
  font-size: 6rem;
  display: flex;
  margin-top: -6rem;
  transition: 300ms ease-in-out;
`

const Box = styled.div`
  width: 100%;
  text-decoration: none;
  color: #111;
  display: block;
  margin-top: 1.5rem;
  background: #222;
  color: #fff;
  padding: 3rem 3rem 1.5rem;
  border-radius: 0.5rem;
  flex: 1;
  border-radius: 0.5rem;
  border-bottom: 0.5rem solid transparent;
  transition: 300ms ease-in-out;
  @media (max-width: ${1280 / 16}rem) {
    margin: 3rem 1.5rem;
  }
  h3 {
    margin: 1.5rem 0;
    font-size: 2.4rem;
  }
  p {
    margin: 1.5rem 0;
    line-height: 1.8;
  }
  &:hover {
    transform: scale(1.05);
    border-color: #a3de83;
  }
`

const Feature = ({ Icon, title, paragraph }) => {
  return (
    <StyledFeature>
      <Box to="/benguerir">
        <BoxIcon>
          <Icon />
        </BoxIcon>
        <h3>{title}</h3>
        <p>{paragraph}</p>
      </Box>
    </StyledFeature>
  )
}

export default Feature
