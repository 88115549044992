// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benguerir-js": () => import("./../src/pages/benguerir.js" /* webpackChunkName: "component---src-pages-benguerir-js" */),
  "component---src-pages-campuses-js": () => import("./../src/pages/campuses.js" /* webpackChunkName: "component---src-pages-campuses-js" */),
  "component---src-pages-concept-js": () => import("./../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-khouribga-js": () => import("./../src/pages/khouribga.js" /* webpackChunkName: "component---src-pages-khouribga-js" */),
  "component---src-pages-legal-notice-and-general-conditions-js": () => import("./../src/pages/legal-notice-and-general-conditions.js" /* webpackChunkName: "component---src-pages-legal-notice-and-general-conditions-js" */),
  "component---src-pages-orientation-js": () => import("./../src/pages/orientation.js" /* webpackChunkName: "component---src-pages-orientation-js" */),
  "component---src-pages-professional-insertion-js": () => import("./../src/pages/professional-insertion.js" /* webpackChunkName: "component---src-pages-professional-insertion-js" */),
  "component---src-pages-steps-js": () => import("./../src/pages/steps.js" /* webpackChunkName: "component---src-pages-steps-js" */),
  "component---src-pages-the-curriculum-js": () => import("./../src/pages/the-curriculum.js" /* webpackChunkName: "component---src-pages-the-curriculum-js" */),
  "component---src-pages-the-pool-js": () => import("./../src/pages/the-pool.js" /* webpackChunkName: "component---src-pages-the-pool-js" */)
}

