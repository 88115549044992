import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { BsFillPlayFill } from "react-icons/bs"
import { RiBuilding2Line, RiQuestionLine } from "react-icons/ri"
import { AiOutlineOrderedList } from "react-icons/ai"
import ModalVideo from "react-modal-video"
import { useIntl, Link } from "gatsby-plugin-intl"

import Container from "../Container"
import SectionTitle from "../SectionTitle"

import "../../assets/css/modal-video.css"

const StyledDna = styled.div`
  padding: 6rem 0;
`

const VideoText = styled.div`
  margin: -6rem 0 0;
  position: relative;
  border-radius: 0.5rem;
  padding: 3rem;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  line-height: 1.8;
  @media (max-width: ${1280 / 16}rem) {
    margin-top: -1.5rem;
  }
`

const StyledImg = styled(Img)`
  transition: 300ms ease-in-out;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.6;
    transition: 300ms ease-in-out;
  }
`

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  background: #a3de83;
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: 300ms ease-in-out;
`

const VideoWrapper = styled.div`
  margin: 6rem -3rem 0;
  padding-top: 40%;
  background: #ccc;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: ${1280 / 16}rem) {
    padding-top: 60%;
    border-radius: 0;
  }
  &:hover {
    ${StyledImg} {
      transform: scale(1.05);
      &::after {
        background: #a3de83;
      }
    }
    ${PlayButton} {
      transform: scale(2) translate(-25%, -25%);
      background: transparent;
    }
  }
`

const Boxes = styled.div`
  display: flex;
  margin: 6rem -1.5rem 0;
  @media (max-width: ${1280 / 16}rem) {
    flex-direction: column;
  }
`

const BoxIcon = styled.div`
  color: #a3de83;
  font-size: 6rem;
  display: flex;
  margin-top: -6rem;
  transition: 300ms ease-in-out;
`

const Box = styled(Link)`
  text-decoration: none;
  color: #111;
  margin: 1.5rem;
  background: #f9f9f9;
  padding: 3rem 3rem 1.5rem;
  border-radius: 0.5rem;
  flex: 1;
  border-radius: 0.5rem;
  border-bottom: 0.5rem solid transparent;
  transition: 300ms ease-in-out;
  @media (max-width: ${1280 / 16}rem) {
    margin: 3rem 1.5rem;
  }
  h3 {
    margin: 1.5rem 0;
    font-size: 2.4rem;
  }
  p {
    margin: 1.5rem 0;
    line-height: 1.8;
  }
  &:hover {
    background: #fff;
    transform: scale(1.05);
    border-color: #a3de83;
    ${BoxIcon} {
      color: #111;
    }
  }
`

const Dna = () => {
  const [modal, setModal] = useState(false)
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "video-cover.jpeg" }) {
        childImageSharp {
          fixed(
            width: 1250
            quality: 100
            duotone: { highlight: "#ffffff", shadow: "#000000", opacity: 50 }
          ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <StyledDna>
      <ModalVideo
        channel="youtube"
        isOpen={modal}
        videoId="kveqI_sZdps"
        onClose={() => setModal(false)}
      />
      <Container>
        <SectionTitle>
          {formatMessage({
            id: "sections.dna.title",
          })}
        </SectionTitle>
        <VideoWrapper onClick={() => setModal(true)}>
          <StyledImg
            fixed={fixed}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
            }}
            draggable={false}
          />
          <PlayButton>
            <BsFillPlayFill />
          </PlayButton>
        </VideoWrapper>
        <VideoText>
          {formatMessage({
            id: "sections.dna.paragraph",
          })
            .split("\n")
            .map((paragraph, key) => (
              <p key={key}>{paragraph}</p>
            ))}
        </VideoText>
        <Boxes>
          <Box to="/campuses">
            <BoxIcon>
              <RiBuilding2Line />
            </BoxIcon>
            <h3>
              {formatMessage({ id: "sections.dna.boxes.campuses.title" })}
            </h3>
            <p>
              {formatMessage({
                id: "sections.dna.boxes.campuses.paragraph",
              })}
            </p>
          </Box>
          <Box to="/steps">
            <BoxIcon>
              <AiOutlineOrderedList />
            </BoxIcon>
            <h3>{formatMessage({ id: "sections.dna.boxes.steps.title" })}</h3>
            <p>
              {formatMessage({
                id: "sections.dna.boxes.steps.paragraph",
              })}
            </p>
          </Box>
          <Box to="/faq">
            <BoxIcon>
              <RiQuestionLine />
            </BoxIcon>
            <h3>{formatMessage({ id: "sections.dna.boxes.faq.title" })}</h3>
            <p>{formatMessage({ id: "sections.dna.boxes.faq.paragraph" })}</p>
          </Box>
        </Boxes>
      </Container>
    </StyledDna>
  )
}

export default Dna
