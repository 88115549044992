import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import CampusTitle from "../components/sections/Title"
import About from "../components/sections/About"

import styled from "styled-components"

const EndSpace = styled.div`
  padding-bottom: 6rem;
`

const Concept = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
    after,
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "orientation/banner.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      after: file(relativePath: { eq: "orientation/after.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <Seo
        title={formatMessage({
          id: "sections.orientation.header.title",
        })}
      />
      <CampusTitle
        title={formatMessage({
          id: "sections.orientation.header.title",
        })}
        fixed={fixed}
      >
        {formatMessage({
          id: "sections.orientation.header.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      </CampusTitle>
      <About
        fixed={after.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.orientation.sections.after.title",
        })}
        paragraph={formatMessage({
          id: "sections.orientation.sections.after.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <span key={key}>{paragraph}</span>
          ))}
      />
      <EndSpace />
    </DefaultLayout>
  )
}

export default Concept
