import React from "react"
import styled from "styled-components"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import Container from "../components/Container"

const Content = styled.div`
  padding: 15rem 0 10rem;
`

const Icon = styled.div`
  text-align: center;
  font-size: 10rem;
  span {
    color: #a2de83;
  }
`

const Title = styled.h1`
  text-align: center;
  span {
    color: #a2de83;
  }
`

const NotFound = () => {
  return (
    <DefaultLayout>
      <Seo title="404" />
      <Content>
        <Container>
          <Icon>
            4<span>0</span>4
          </Icon>
          <Title>
            This <span>Page</span> Doesn't Exist ;)
          </Title>
        </Container>
      </Content>
    </DefaultLayout>
  )
}

export default NotFound
