import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { StoreProvider } from "easy-peasy"
import { normalize } from "styled-normalize"

import store from "./src/store"
import defaultTheme from "./src/themes/default"

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    box-sizing: border-box;
    font-size:62.5%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Work Sans', 'Tajawal', sans-serif;
    line-height: 1.5;
    color: #111;
    font-size: 1.6rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Archivo Black', 'Lalezar', cursive;
  }

  .BrainhubCarousel__arrows {
    background: #a3de83;
    border-radius: 50%;
    margin: 30px;
    transition: 300ms ease-in-out;
  }

  .BrainhubCarousel__arrows:hover {
    background: #111 !important;
  }

  .BrainhubCarousel__arrows span {
    border-color: #111;
  }
  .BrainhubCarousel__arrows:hover span {
    border-color: #fff;
  }
`

export const wrapRootElement = ({ element }) => (
  <StoreProvider store={store}>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  </StoreProvider>
)
