import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import RTL from "../helpers/RTL"
import Navbar from "../components/Navbar"
import Drawer from "../components/Drawer"
import Footer from "../components/Footer"

const DefaultLayout = ({ children }) => {
  const intl = useIntl()

  return (
    <RTL>
      <div dir={intl.locale === "ar" ? "rtl" : "ltr"}>
        <Navbar />
        <Drawer />
        {children}
        <Footer />
      </div>
    </RTL>
  )
}

export default DefaultLayout
