import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Seo from "../components/Seo"
import { RiMacLine, RiGamepadLine, RiBuilding2Line } from "react-icons/ri"
import { BsFillPeopleFill } from "react-icons/bs"
import { MdLocationOn, MdClose } from "react-icons/md"
import Img from "gatsby-image"
import Carousel, { autoplayPlugin } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import DefaultLayout from "../layouts/default"
import CampusTitle from "../components/sections/Title"
import About from "../components/sections/About"
import Container from "../components/Container"

const CampusContent = styled.div`
  padding: 0 0 6rem;
`

const Features = styled.ul`
  margin: 3rem 0;
  padding: 0;
  list-style: none;
`

const FeaturesItem = styled.li`
  display: flex;
  margin: 1.5rem 0;
  align-items: center;
`

const FeaturesIcon = styled.div`
  display: flex;
  margin-right: 1.5rem;
  font-size: 2.4rem;
  color: #a3de83;
`

const Boxes = styled.div`
  display: flex;
  margin: 4rem -1.5rem;
  @media (max-width: ${1280 / 16}rem) {
    flex-direction: column;
  }
`

const Box = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  margin: 1.5rem;
  background: linear-gradient(
      rgba(17, 17, 17, 0.1),
      rgba(17, 17, 17, 0.9),
      rgba(17, 17, 17, 1)
    ),
    url("${({ bg }) => bg}");
  background-position: center center;
  background-size: cover;
  padding: 3rem 3rem 1.5rem;
  border-radius: 0.5rem;
  flex: 1;
  border-radius: 0.5rem;
  border-bottom: 0.5rem solid transparent;
  transition: 300ms ease-in-out;
  border-color: #111111;
  @media (max-width: ${1280 / 16}rem) {
    margin: 3rem 1.5rem;
  }
  h3 {
    margin: 3rem 0;
    font-size: 2.4rem;
  }
  p {
    margin: 3rem 0;
    line-height: 1.8;
  }
  &:hover {
    transform: scale(1.05);
    border-color: #a3de83;
  }
`

const BoxIcon = styled.div`
  color: #a3de83;
  font-size: 6rem;
  display: flex;
  margin-top: -6rem;
  transition: 300ms ease-in-out;
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1999;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ modal }) => (modal ? "visible" : "hidden")};
  opacity: ${({ modal }) => (modal ? 1 : 0)};
  transition: 300ms ease-in-out;
`

const CloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
`

const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
`

const Benguerir = () => {
  const [modal, setModal] = useState(false)
  const { formatMessage } = useIntl()
  const {
    benguerir,
    khouribga,
    cluster,
    playground,
    cafeteria,
    meetings,
    clusterKh,
    playgroundKh,
    cafeteriaKh,
    meetingsKh,
    clusterBg,
    playgroundBg,
    meetingsBg,
    martil,
    med1,
    med2,
    med3,
    med4,
    med5,
    med6,
    med7,
  } = useStaticQuery(graphql`
    query {
      benguerir: file(relativePath: { eq: "benguerir/cluster.jpeg" }) {
        childImageSharp {
          fixed(width: 1920, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      khouribga: file(relativePath: { eq: "khouribga/cluster.jpeg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cluster: file(relativePath: { eq: "khouribga/cluster.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      playground: file(relativePath: { eq: "campuses/playground.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cafeteria: file(relativePath: { eq: "khouribga/cafeteria.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      meetings: file(relativePath: { eq: "khouribga/meetings.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      clusterKh: file(relativePath: { eq: "khouribga/cluster.jpg" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      playgroundKh: file(relativePath: { eq: "khouribga/playground.jpg" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cafeteriaKh: file(relativePath: { eq: "khouribga/cafeteria.jpg" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      meetingsKh: file(relativePath: { eq: "khouribga/meetings.jpg" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      clusterBg: file(relativePath: { eq: "benguerir/cluster.jpeg" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      playgroundBg: file(relativePath: { eq: "benguerir/playground.jpg" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      meetingsBg: file(relativePath: { eq: "benguerir/meetings.jpeg" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      martil: file(relativePath: { eq: "med/med1.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      med1: file(relativePath: { eq: "med/med1.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      med2: file(relativePath: { eq: "med/med2.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      med3: file(relativePath: { eq: "med/med3.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      med4: file(relativePath: { eq: "med/med4.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      med5: file(relativePath: { eq: "med/med5.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      med6: file(relativePath: { eq: "med/med6.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      med7: file(relativePath: { eq: "med/med7.png" }) {
        childImageSharp {
          fixed(height: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Modal modal={!!modal}>
        <CloseButton onClick={() => setModal(false)}>
          <MdClose />
        </CloseButton>
        {modal === "kh" && (
          <Carousel
            plugins={[
              "arrows",
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 2000,
                },
              },
            ]}
            animationSpeed={1000}
          >
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={clusterKh.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={playgroundKh.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={cafeteriaKh.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={meetingsKh.childImageSharp.fixed}
              />
            </ImageContainer>
          </Carousel>
        )}
        {modal === "bg" && (
          <Carousel
            plugins={[
              "arrows",
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 2000,
                },
              },
            ]}
            animationSpeed={1000}
          >
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={clusterBg.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={playgroundBg.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={meetingsBg.childImageSharp.fixed}
              />
            </ImageContainer>
          </Carousel>
        )}
        {modal === "med" && (
          <Carousel
            plugins={[
              "arrows",
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 2000,
                },
              },
            ]}
            animationSpeed={1000}
          >
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={med1.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={med2.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={med3.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={med4.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={med5.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={med6.childImageSharp.fixed}
              />
            </ImageContainer>
            <ImageContainer>
              <Img
                imgStyle={{ objectFit: "contain" }}
                fixed={med7.childImageSharp.fixed}
              />
            </ImageContainer>
          </Carousel>
        )}
      </Modal>
      <DefaultLayout>
        <CampusContent>
          <Seo
            title={formatMessage({
              id: "sections.campus.campuses.header.title",
            })}
          />
          <CampusTitle
            title={formatMessage({
              id: "sections.campus.campuses.header.title",
            })}
            fixed={benguerir.childImageSharp.fixed}
          >
            {formatMessage({ id: "sections.campus.campuses.header.paragraph" })
              .split("\n")
              .map((paragraph, key) => (
                <p key={key}>{paragraph}</p>
              ))}
          </CampusTitle>
          <Container>
            <Boxes>
              <Box
                bg={khouribga.childImageSharp.fixed.src}
                onClick={() => setModal("kh")}
              >
                <BoxIcon>
                  <RiBuilding2Line />
                </BoxIcon>
                <h3>
                  {formatMessage({
                    id: "sections.campus.campuses.sections.khouribga.title",
                  })}
                </h3>
                <p>
                  {formatMessage({
                    id: "sections.campus.campuses.sections.khouribga.paragraph",
                  })}
                </p>
                <Features>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <RiMacLine />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.khouribga.list.capacity",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <RiGamepadLine />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.khouribga.list.playgrounds",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <BsFillPeopleFill />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.khouribga.list.meetingRooms",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <MdLocationOn />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.khouribga.list.location",
                    })}
                  </FeaturesItem>
                </Features>
              </Box>
              <Box
                bg={benguerir.childImageSharp.fixed.src}
                onClick={() => setModal("bg")}
              >
                <BoxIcon>
                  <RiBuilding2Line />
                </BoxIcon>
                <h3>
                  {formatMessage({
                    id: "sections.campus.campuses.sections.benguerir.title",
                  })}
                </h3>
                <p>
                  {formatMessage({
                    id: "sections.campus.campuses.sections.benguerir.paragraph",
                  })}
                </p>
                <Features>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <RiMacLine />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.benguerir.list.capacity",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <RiGamepadLine />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.benguerir.list.playgrounds",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <BsFillPeopleFill />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.benguerir.list.meetingRooms",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <MdLocationOn />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.benguerir.list.location",
                    })}
                  </FeaturesItem>
                </Features>
              </Box>
              <Box
                bg={martil.childImageSharp.fixed.src}
                onClick={() => setModal("med")}
              >
                <BoxIcon>
                  <RiBuilding2Line />
                </BoxIcon>
                <h3>
                  {formatMessage({
                    id: "sections.campus.campuses.sections.martil.title",
                  })}
                </h3>
                <p>
                  {formatMessage({
                    id: "sections.campus.campuses.sections.martil.paragraph",
                  })}
                </p>
                <Features>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <RiMacLine />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.martil.list.capacity",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <RiGamepadLine />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.martil.list.playgrounds",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <BsFillPeopleFill />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.martil.list.meetingRooms",
                    })}
                  </FeaturesItem>
                  <FeaturesItem>
                    <FeaturesIcon>
                      <MdLocationOn />
                    </FeaturesIcon>
                    {formatMessage({
                      id:
                        "sections.campus.campuses.sections.martil.list.location",
                    })}
                  </FeaturesItem>
                </Features>
              </Box>
            </Boxes>
          </Container>
          <About
            fixed={cluster.childImageSharp.fixed}
            title={formatMessage({
              id: "sections.campus.campuses.sections.clusters.title",
            })}
            paragraph={formatMessage({
              id: "sections.campus.campuses.sections.clusters.paragraph",
            })}
          />
          <About
            fixed={playground.childImageSharp.fixed}
            reverse
            title={formatMessage({
              id: "sections.campus.campuses.sections.playgrounds.title",
            })}
            paragraph={formatMessage({
              id: "sections.campus.campuses.sections.playgrounds.paragraph",
            })}
          />
          <About
            fixed={cafeteria.childImageSharp.fixed}
            title={formatMessage({
              id: "sections.campus.campuses.sections.cafeteria.title",
            })}
            paragraph={formatMessage({
              id: "sections.campus.campuses.sections.cafeteria.paragraph",
            })}
          />
          <About
            fixed={meetings.childImageSharp.fixed}
            reverse
            title={formatMessage({
              id: "sections.campus.campuses.sections.meetings.title",
            })}
            paragraph={formatMessage({
              id: "sections.campus.campuses.sections.meetings.paragraph",
            })}
          />
        </CampusContent>
      </DefaultLayout>
    </>
  )
}

export default Benguerir
