import React from "react"
import styled from "styled-components"
import { useIntl, Link, changeLocale } from "gatsby-plugin-intl"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FiChevronDown, FiMenu, FiX } from "react-icons/fi"
import { MdTranslate } from "react-icons/md"

import useHideOnScroll from "../hooks/useHideOnScroll"

import Logo from "../assets/images/logo.svg"

import en from "../assets/images/flags/en.svg"
import fr from "../assets/images/flags/fr.svg"
import ar from "../assets/images/flags/ar.svg"
import tzm from "../assets/images/flags/tzm.svg"

import navbarData from "../../content/data/navbar.json"

const flags = { en, fr, ar, tzm }

const NavbarWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  max-width: 128rem;
  width: 100%;
  left: 50%;
  transform: translate(-50%, ${({ hide }) => (hide ? -100 : 0)}%);
  transition: 150ms ease-in-out;
  @media (max-width: ${1280 / 16}rem) {
    left: 0;
    transform: translate(0, ${({ hide }) => (hide ? -100 : 0)}%);
  }
`

const StyledNavbar = styled.div`
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(1.5rem);
  height: 8rem;
  color: #fff;
  max-width: 128rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 0 0 0.5rem 0.5rem;
  justify-content: space-between;
  @media (max-width: ${1280 / 16}rem) {
    border-radius: 0;
  }
`

const NavbarSide = styled.div`
  display: flex;
  margin: 0 3rem;
`

const LogoLink = styled(Link)`
  display: block;
  margin-left: -1.2rem;
  margin-right: 1.5rem;
  padding: 1.2rem;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
  svg {
    display: block;
  }
`

const NavbarList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  @media (max-width: ${({ hideon }) => `${hideon / 16}rem`}) {
    display: none;
  }
`

const NavbarLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem 2.5rem;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
`

const NavbarLinkHover = styled.a`
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
  cursor: pointer;
`

const StyledFiChevronDown = styled(FiChevronDown)`
  margin-left: 0.5rem;
`

const NavbarSubList = styled.ul`
  visibility: hidden;
  margin: 0;
  padding: 1rem;
  list-style: none;
  min-width: 20rem;
  position: absolute;
  top: 100%;
  ${({ reverse }) => (reverse ? "right" : "left")}: 0;
  transform: translateY(1rem);
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-top: 0.5rem solid #a3de83;
  border-radius: 0.5rem;
  opacity: 0;
  transition: 150ms ease-in-out;
`

const NavbarSubLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: #111;
  padding: 1rem 2rem;
  white-space: nowrap;
  transition: 150ms ease-in-out;
  border-radius: 0.5rem;
  &:hover {
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    background: #f0f0f0;
  }
`

const NavbarSubLinkHover = styled.a`
  display: block;
  text-decoration: none;
  color: #111;
  padding: 1rem 2rem;
  white-space: nowrap;
  transition: 150ms ease-in-out;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    background: #f0f0f0;
  }
`

const NavbarItem = styled.li`
  position: relative;
  &:hover ${NavbarLink}, &:hover ${NavbarLinkHover} {
    color: #a3de83;
    background: rgba(0, 0, 0, 0.25);
  }
  &:hover ${NavbarSubList} {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
`
const StyledMdTranslate = styled(MdTranslate)`
  margin-right: 0.5rem;
`

const ApplyButton = styled(Link)`
  text-decoration: none;
  color: #111;
  background: #a3de83;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
  &:hover {
    background: #fff;
  }
  @media (max-width: ${({ hideon }) => `${hideon / 16}rem`}) {
    display: none;
  }
`

const MenuButton = styled.button`
  padding: 1.4rem;
  background: #222;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  outline: none;
  margin-left: 1.5rem;
  transition: 150ms ease-in-out;
  &:hover {
    background: #333;
  }
  svg {
    display: block;
  }
  @media (min-width: ${({ showon }) => `${showon / 16}rem`}) {
    display: none;
  }
`

const Navbar = () => {
  const { formatMessage, locale } = useIntl()
  const { drawerIsOpen } = useStoreState(({ ui }) => ui)
  const { toggleDrawer } = useStoreActions(({ ui }) => ui)
  const scroll = useHideOnScroll()

  return (
    <NavbarWrapper hide={scroll}>
      <StyledNavbar>
        <NavbarSide>
          <LogoLink to="/">
            <Logo />
          </LogoLink>
          <NavbarList hideon={1280}>
            {navbarData.list.map(({ title, route, subList }) => (
              <NavbarItem key={title}>
                {subList ? (
                  <NavbarLinkHover>
                    {formatMessage({ id: `navbar.${title}` })}
                    <StyledFiChevronDown />
                  </NavbarLinkHover>
                ) : (
                  <NavbarLink to={route}>
                    {formatMessage({ id: `navbar.${title}` })}
                  </NavbarLink>
                )}
                {subList && (
                  <NavbarSubList>
                    {subList.map(({ title, route }) => (
                      <li key={title}>
                        <NavbarSubLink to={route}>
                          {formatMessage({ id: `navbar.${title}` })}
                        </NavbarSubLink>
                      </li>
                    ))}
                  </NavbarSubList>
                )}
              </NavbarItem>
            ))}
          </NavbarList>
        </NavbarSide>
        <NavbarSide>
          <NavbarList hideon={768}>
            <NavbarItem>
              <NavbarLinkHover>
                <StyledMdTranslate />
                {navbarData.languages[locale]}
                <StyledFiChevronDown />
              </NavbarLinkHover>
              <NavbarSubList reverse>
                {Object.keys(navbarData.languages)
                  .filter(lang => lang !== locale)
                  .map(lang => {
                    const Flag = flags[lang]
                    return (
                      <li key={lang}>
                        <NavbarSubLinkHover onClick={() => changeLocale(lang)}>
                          <Flag />
                          {navbarData.languages[lang]}
                        </NavbarSubLinkHover>
                      </li>
                    )
                  })}
              </NavbarSubList>
            </NavbarItem>
          </NavbarList>
          <ApplyButton to="/legal-notice-and-general-conditions" hideon={768}>
            {formatMessage({ id: `navbar.applyNow` })}
          </ApplyButton>
          <MenuButton
            open={drawerIsOpen}
            showon={1280}
            onClick={() => toggleDrawer()}
          >
            {drawerIsOpen ? <FiX /> : <FiMenu />}
          </MenuButton>
        </NavbarSide>
      </StyledNavbar>
    </NavbarWrapper>
  )
}

export default Navbar
