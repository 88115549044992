import React from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"

import favicon from "../assets/images/favicon.ico"

const Seo = ({ title, description }) => {
  const { formatMessage } = useIntl()
  const defaultTitle = formatMessage({ id: "helmet.title" })
  const defaultDescription = formatMessage({ id: "helmet.description" })

  return (
    <Helmet>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <link rel="shortcut icon" href={favicon} type="image/x-icon" />
      <link rel="icon" href={favicon} type="image/x-icon" />
    </Helmet>
  )
}

export default Seo
