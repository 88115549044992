import React from "react"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import Banner from "../components/sections/Banner"
import Dna from "../components/sections/Dna"
import Why from "../components/sections/Why"
import Road from "../components/sections/Road"

const Index = () => {
  return (
    <DefaultLayout>
      <Seo />
      <Banner />
      <Dna />
      <Why />
      <Road />
    </DefaultLayout>
  )
}

export default Index
