import React, { Fragment, useState } from "react"
import styled from "styled-components"
import { useStoreActions, useStoreState } from "easy-peasy"
import { Link, useIntl, changeLocale } from "gatsby-plugin-intl"
import { FiChevronDown, FiX } from "react-icons/fi"
import { MdTranslate } from "react-icons/md"

import en from "../assets/images/flags/en.svg"
import fr from "../assets/images/flags/fr.svg"
import ar from "../assets/images/flags/ar.svg"
import tzm from "../assets/images/flags/tzm.svg"

import navbarData from "../../content/data/navbar.json"

const flags = { en, fr, ar, tzm }

const StyledDrawer = styled.div`
  position: fixed;
  z-index: 1001;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  transition: 300ms ease-in-out;
`

const DrawerSide = styled.div`
  cursor: auto;
  padding: 3rem 1.5rem;
  position: absolute;
  right: 0;
  transform: translateX(${({ open }) => (open ? 0 : 100)}%);
  width: 30rem;
  max-width: 100%;
  height: 100%;
  background: #fff;
  transition: 300ms ease-in-out;
`

const DrawerList = styled.ul`
  margin: 0;
  padding: 4.7rem 1.5rem 0;
  list-style: none;
  height: 100%;
  overflow-y: auto;
`

const DrawerLink = styled(Link)`
  text-decoration: none;
  color: #111;
  display: block;
  padding: 1.5rem 3rem;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
  user-select: none;
  &:hover {
    padding-left: 2.5rem;
    padding-right: 3.5rem;
    background: #f0f0f0;
  }
`

const DrawerLinkHover = styled.a`
  text-decoration: none;
  color: #111;
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 150ms ease-in-out;
  user-select: none;
  &:hover {
    padding-left: 2.5rem;
    padding-right: 3.5rem;
    background: #f0f0f0;
  }
`

const DrawerItem = styled.li``

const StyledFiChevronDown = styled(FiChevronDown)`
  margin-left: 0.5rem;
  transition: 300ms ease-in-out;
  transform: rotate(${({ isopen: { boolean } }) => (boolean ? 180 : 0)}deg);
`

const StyledDrawerSubList = styled.ul`
  margin: 1.5rem 0;
  padding: 1rem;
  list-style: none;
  border-radius: 0.5rem;
  border-left: 0.5rem solid #a3de83;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
`

const DrawerSubLink = styled(Link)`
  text-decoration: none;
  color: #111;
  display: block;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
  &:hover {
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    background: #f0f0f0;
  }
`

const DrawerSubLinkHover = styled.a`
  text-decoration: none;
  color: #111;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 150ms ease-in-out;
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    background: #f0f0f0;
  }
`

const DrawerSubListWrapper = styled.div`
  opacity: ${({ isopen: { boolean } }) => (boolean ? 1 : 0)};
  height: ${({ isopen: { boolean } }) => (boolean ? "auto" : 0)};
  transition: 300ms ease-in-out;
  overflow: hidden;
  padding: 0 1.5rem;
  margin: 0 -1.5rem;
`

const StyledMdTranslate = styled(MdTranslate)`
  margin-right: 0.5rem;
`

const MenuButton = styled.button`
  padding: 1.4rem;
  background: #f0f0f0;
  border: none;
  border-radius: 50%;
  color: #111;
  cursor: pointer;
  outline: none;
  transition: 150ms ease-in-out;
  position: absolute;
  right: 3rem;
  top: 1.8rem;
  &:hover {
    background: #e0e0e0;
  }
  svg {
    display: block;
  }
`

const ApplyButton = styled(Link)`
  text-decoration: none;
  color: #111;
  background: #a3de83;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
  display: block;
  text-align: center;
  margin-top: 1.5rem;
  &:hover {
    background: #f0f0f0;
  }
`

const DrawerSubList = ({ title, subList, languageList }) => {
  const [subListIsOpen, setSubListIsOpen] = useState(false)
  const { formatMessage, locale } = useIntl()

  return (
    <Fragment>
      <DrawerLinkHover onClick={() => setSubListIsOpen(!subListIsOpen)}>
        {languageList ? (
          <Fragment>
            <StyledMdTranslate />
            {navbarData.languages[locale]}
          </Fragment>
        ) : (
          formatMessage({ id: `navbar.${title}` })
        )}
        <StyledFiChevronDown isopen={{ boolean: subListIsOpen }} />
      </DrawerLinkHover>
      <DrawerSubListWrapper isopen={{ boolean: subListIsOpen }}>
        <StyledDrawerSubList>
          {languageList
            ? Object.keys(navbarData.languages)
                .filter(lang => lang !== locale)
                .map(lang => {
                  const Flag = flags[lang]
                  return (
                    <li key={lang}>
                      <DrawerSubLinkHover onClick={() => changeLocale(lang)}>
                        <Flag />
                        {navbarData.languages[lang]}
                      </DrawerSubLinkHover>
                    </li>
                  )
                })
            : subList.map(({ title, route }) => (
                <li key={title}>
                  <DrawerSubLink to={route}>
                    {formatMessage({ id: `navbar.${title}` })}
                  </DrawerSubLink>
                </li>
              ))}
        </StyledDrawerSubList>
      </DrawerSubListWrapper>
    </Fragment>
  )
}

const Drawer = () => {
  const { drawerIsOpen } = useStoreState(({ ui }) => ui)
  const { toggleDrawer } = useStoreActions(({ ui }) => ui)
  const { formatMessage } = useIntl()

  return (
    <StyledDrawer open={drawerIsOpen} onClick={() => toggleDrawer()}>
      <DrawerSide open={drawerIsOpen} onClick={e => e.stopPropagation()}>
        <DrawerList>
          <DrawerSubList languageList subList={[]} />
          {navbarData.list.map(({ title, route, subList }) => (
            <DrawerItem key={title}>
              {subList ? (
                <DrawerSubList title={title} subList={subList} />
              ) : (
                <DrawerLink to={route}>
                  {formatMessage({ id: `navbar.${title}` })}
                </DrawerLink>
              )}
            </DrawerItem>
          ))}
          <ApplyButton to="/legal-notice-and-general-conditions" hideon={768}>
            {formatMessage({ id: `navbar.applyNow` })}
          </ApplyButton>
        </DrawerList>
      </DrawerSide>
      <MenuButton>
        <FiX />
      </MenuButton>
    </StyledDrawer>
  )
}

export default Drawer
