import React, { useState } from "react"
import styled from "styled-components"
import { FaPlus } from "react-icons/fa"

const Item = styled.div`
  margin-bottom: 1.5rem;
  background: #f9f9f9;
  border-radius: 0.5rem;
  border-left: 0.5rem solid #a3de83;
  transition: 150ms ease-in-out;
  &:hover {
    ${({ isopen }) => !isopen && "background: #f0f0f0;"}
  }
`

const ItemTitle = styled.h4`
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: 150ms ease-in-out;
  &:hover {
    ${({ isopen }) => !isopen && "padding-left: 2.5rem;"}
  }
`

const ItemContent = styled.div`
  padding: 0 3rem 3rem;
`

const StyledFaPlus = styled(FaPlus)`
  margin-right: 1.5rem;
  color: #a3de83;
  transition: 150ms ease-in-out;
  transform: rotate(${({ isopen }) => (isopen ? 90 : 0)}deg);
`

const FaqQuestion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(0)

  return (
    <Item isopen={isOpen}>
      <ItemTitle isopen={isOpen} onClick={() => setIsOpen(isOpen ? 0 : 1)}>
        <StyledFaPlus isopen={isOpen} />
        {title}
      </ItemTitle>
      {!!isOpen && <ItemContent>{children}</ItemContent>}
    </Item>
  )
}

export default FaqQuestion
