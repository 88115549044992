import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import { FiBriefcase } from "react-icons/fi"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import Title from "../components/sections/Title"
import Container from "../components/Container"

const ParagraphSection = styled.div`
  margin: 10rem 0;
  font-size: 1.8rem;
  line-height: 1.8;
`

const Boxes = styled.div`
  display: flex;
  margin: 6rem -1.5rem 0;
  flex-direction: column;
`

const BoxIcon = styled.div`
  color: #a3de83;
  font-size: 6rem;
  display: flex;
  margin-top: -6rem;
  transition: 300ms ease-in-out;
`

const Box = styled.div`
  text-decoration: none;
  color: #111;
  margin: 3rem 0;
  background: #f9f9f9;
  padding: 3rem 3rem 1.5rem;
  border-radius: 0.5rem;
  flex: 1;
  border-radius: 0.5rem;
  border-bottom: 0.5rem solid transparent;
  transition: 300ms ease-in-out;
  h3 {
    margin: 1.5rem 0;
    font-size: 2.4rem;
    span {
      color: #a3de83;
    }
  }
  h4 {
    color: #a2de83;
  }
  p {
    margin: 1.5rem 0;
    line-height: 1.8;
  }
`

const ProfessionalInsertion = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "professional/banner.jpeg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <Seo
        title={formatMessage({
          id: "sections.professionalInsertion.header.title",
        })}
      />
      <Title
        title={formatMessage({
          id: "sections.professionalInsertion.header.title",
        })}
        fixed={fixed}
      >
        {formatMessage({
          id: "sections.professionalInsertion.header.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      </Title>
      <Container>
        <ParagraphSection>
          {formatMessage({
            id: "sections.professionalInsertion.paragraph1",
          })
            .split("\n")
            .map((paragraph, key) => (
              <p key={key}>{paragraph}</p>
            ))}
        </ParagraphSection>
        <Boxes>
          <Box>
            <BoxIcon>
              <FiBriefcase />
            </BoxIcon>
            <h3>
              <span>#1 </span>-{" "}
              {formatMessage({
                id: "sections.professionalInsertion.steps.first.title",
              })}
            </h3>
            <h4>
              {formatMessage({
                id: "sections.professionalInsertion.steps.first.subtitle",
              })}
            </h4>
            <p>
              {formatMessage({
                id: "sections.professionalInsertion.steps.first.paragraph",
              })}
            </p>
          </Box>
          <Box>
            <BoxIcon>
              <FiBriefcase />
            </BoxIcon>
            <h3>
              <span>#2 </span>-{" "}
              {formatMessage({
                id: "sections.professionalInsertion.steps.second.title",
              })}
            </h3>
            <h4>
              {formatMessage({
                id: "sections.professionalInsertion.steps.second.subtitle",
              })}
            </h4>
            <p>
              {formatMessage({
                id: "sections.professionalInsertion.steps.second.paragraph",
              })}
            </p>
          </Box>
          <Box>
            <BoxIcon>
              <FiBriefcase />
            </BoxIcon>
            <h3>
              <span>#3 </span>-{" "}
              {formatMessage({
                id: "sections.professionalInsertion.steps.third.title",
              })}
            </h3>
            <h4>
              {formatMessage({
                id: "sections.professionalInsertion.steps.third.subtitle",
              })}
            </h4>
            <p>
              {formatMessage({
                id: "sections.professionalInsertion.steps.third.paragraph",
              })}
            </p>
          </Box>
        </Boxes>
        <ParagraphSection>
          {formatMessage({
            id: "sections.professionalInsertion.paragraph2",
          })
            .split("\n")
            .map((paragraph, key) => (
              <p key={key}>{paragraph}</p>
            ))}
        </ParagraphSection>
      </Container>
    </DefaultLayout>
  )
}

export default ProfessionalInsertion
