import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import SectionTitle from "../components/SectionTitle"
import Container from "../components/Container"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import CampusTitle from "../components/sections/Title"
import About from "../components/sections/About"

const TitleSection = styled.div`
  margin: 10rem 0;
  text-align: center;
`

const EndSpace = styled.div`
  padding-bottom: 10rem;
`

const Steps = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
    conditions,
    online,
    checkin,
    pool,
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "steps/banner.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      conditions: file(relativePath: { eq: "steps/conditions.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      online: file(relativePath: { eq: "steps/online.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      checkin: file(relativePath: { eq: "steps/checkin.jpeg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pool: file(relativePath: { eq: "steps/thepool.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <Seo
        title={formatMessage({
          id: "sections.steps.header.title",
        })}
      />
      <CampusTitle
        title={formatMessage({
          id: "sections.steps.header.title",
        })}
        fixed={fixed}
      >
        {formatMessage({
          id: "sections.steps.header.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      </CampusTitle>
      <Container>
        <TitleSection>
          <SectionTitle>
            {formatMessage({
              id: "sections.steps.sections.conditions.header",
            })}
          </SectionTitle>
        </TitleSection>
      </Container>
      <About
        fixed={conditions.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.steps.sections.conditions.title",
        })}
        paragraph={formatMessage({
          id: "sections.steps.sections.conditions.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <span key={key}>{paragraph}</span>
          ))}
        reverse
      />
      <Container>
        <TitleSection>
          <SectionTitle>
            {formatMessage({
              id: "sections.steps.sections.onlineTests.header",
            })}
          </SectionTitle>
        </TitleSection>
      </Container>
      <About
        fixed={online.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.steps.sections.onlineTests.title",
        })}
        paragraph={formatMessage({
          id: "sections.steps.sections.onlineTests.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <span key={key}>{paragraph}</span>
          ))}
      />
      <Container>
        <TitleSection>
          <SectionTitle>
            {formatMessage({
              id: "sections.steps.sections.checkin.header",
            })}
          </SectionTitle>
        </TitleSection>
      </Container>
      <About
        fixed={checkin.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.steps.sections.checkin.title",
        })}
        paragraph={formatMessage({
          id: "sections.steps.sections.checkin.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <span key={key}>{paragraph}</span>
          ))}
        reverse
      />
      <Container>
        <TitleSection>
          <SectionTitle>
            {formatMessage({
              id: "sections.steps.sections.thePool.header",
            })}
          </SectionTitle>
        </TitleSection>
      </Container>
      <About
        fixed={pool.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.steps.sections.thePool.title",
        })}
        paragraph={formatMessage({
          id: "sections.steps.sections.thePool.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <span key={key}>{paragraph}</span>
          ))}
      />
      <EndSpace />
    </DefaultLayout>
  )
}

export default Steps
