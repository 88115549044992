import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"
import Img from "gatsby-image"
import Particles from "react-particles-js"
import { useIntl } from "gatsby-plugin-intl"

import SectionTitle from "../SectionTitle"

import Divider from "../../assets/images/divider.svg"
import Container from "../Container"

const StyledWhy = styled.div`
  padding: 10rem 0 13rem;
  color: #fff;
  position: relative;
  overflow: hidden;
`

const StyledDividerTop = styled(Divider)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transform: rotate(180deg);
`

const StyledDividerBottom = styled(Divider)`
  position: absolute;
  left: 0;
  bottom: -1px;
  z-index: 2;
`

const StyledImg = styled(Img)`
  position: absolute;
  top: -1px;
  left: 0;
`

const StyledParallax = styled(Parallax)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  .parallax-inner {
    height: 100%;
  }
`

const WhyContent = styled.div`
  position: relative;
  top: 0;
  z-index: 2;
  text-align: center;
`

const Why = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "banner4.jpg" }) {
        childImageSharp {
          fixed(
            width: 1500
            duotone: { highlight: "#ffffff", shadow: "#000000", opacity: 50 }
          ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <StyledWhy>
      <ParallaxProvider>
        <WhyContent>
          <Container>
            <SectionTitle dark>
              {formatMessage({
                id: "sections.why.title",
              })}
            </SectionTitle>
            {formatMessage({
              id: "sections.why.paragraph",
            })
              .split("\n")
              .map((paragraph, key) => (
                <p key={key}>{paragraph}</p>
              ))}
          </Container>
        </WhyContent>
        <StyledDividerTop />
        <StyledDividerBottom />
        <Particles
          height="100%"
          width="100%"
          style={{
            position: "absolute",
            top: 0,
            zIndex: 1,
            background: "rgba(0,0,0, 0.75)",
          }}
          params={{
            particles: {
              number: {
                value: 37,
              },
              size: {
                value: 3,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
        />
        <StyledParallax y={["-50%", "0%"]}>
          <StyledImg
            fixed={fixed}
            style={{ width: "100%", height: "150%" }}
            draggable={false}
          />
        </StyledParallax>
      </ParallaxProvider>
    </StyledWhy>
  )
}

export default Why
