import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

import Container from "../Container"
import SectionTitle from "../SectionTitle"
import Divider from "../../assets/images/divider2.svg"

const StyledAbout = styled.div`
  margin-top: 6rem;
  border-radius: 1rem;
  background: #f9f9f9;
  display: flex;
  min-height: 40rem;
  overflow: hidden;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  @media (max-width: ${1280 / 16}rem) {
    flex-direction: column-reverse;
    min-height: auto;
  }
`

const AboutCol = styled.div`
  position: relative;
  width: 50%;
  padding: ${({ isImg }) => (isImg ? 0 : "3rem 4.5rem")};
  @media (max-width: ${1280 / 16}rem) {
    width: 100%;
    ${({ isImg }) => isImg && "height: 30rem"}
  }
  h3 {
    margin: 1.5rem 0;
    font-size: 2.4rem;
  }
  p {
    line-height: 1.8;
  }
  span {
    display: block;
    margin: 1.5rem 0;
  }
`

const StyledDivider = styled(Divider)`
  position: absolute;
  display: flex;
  ${({ reverse }) => (reverse ? "right: 0" : "left: 0")};
  top: 0;
  height: 100%;
  width: 30px;
  z-index: 2;
  transform: ${({ reverse, locale }) =>
    reverse
      ? locale === "ar"
        ? "rotate(180deg)"
        : "rotate(0deg)"
      : locale === "ar"
      ? "rotate(0deg)"
      : "rotate(180deg)"};
  @media (max-width: ${1280 / 16}rem) {
    display: none;
  }
`

const About = ({
  fixed,
  reverse,
  title,
  paragraph,
  button: Button,
  features: Features,
}) => {
  const { locale } = useIntl()

  return (
    <Container>
      <StyledAbout reverse={reverse}>
        <AboutCol>
          <SectionTitle notCenter small>
            {title}
          </SectionTitle>
          <p>{paragraph}</p>
          {Features && <Features />}
          {Button && <Button />}
        </AboutCol>
        <AboutCol isImg>
          <StyledDivider reverse={reverse} locale={locale} />
          <Img
            fixed={fixed}
            style={{
              width: "100%",
              height: "100%",
              display: "block",
            }}
            draggable={false}
          />
        </AboutCol>
      </StyledAbout>
    </Container>
  )
}

export default About
