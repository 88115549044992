import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import {
  FaRegKeyboard,
  FaBrain,
  FaDocker,
  FaNetworkWired,
} from "react-icons/fa"
import { BsCodeSlash, BsBraces } from "react-icons/bs"
import {
  RiGamepadLine,
  RiBrush3Line,
  RiComputerLine,
  RiZoomInLine,
  RiBuildingLine,
} from "react-icons/ri"
import { MdSecurity } from "react-icons/md"
import { FiDatabase, FiSettings } from "react-icons/fi"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import Title from "../components/sections/Title"
import Container from "../components/Container"
import About from "../components/sections/About"
import Features from "../components/sections/Features"
import Feature from "../components/Feature"

const ParagraphSection = styled.div`
  margin: 10rem 0;
  font-size: 1.8rem;
  line-height: 1.8;
`

const TheCurriculum = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
    computerProgramming,
    innovation,
    infrastructures,
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "curriculum/banner.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      computerProgramming: file(relativePath: { eq: "curriculum/comp.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      innovation: file(relativePath: { eq: "curriculum/innovation.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      infrastructures: file(relativePath: { eq: "curriculum/infra.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <Seo
        title={formatMessage({
          id: "sections.curriculum.header.title",
        })}
      />
      <Title
        title={formatMessage({
          id: "sections.curriculum.header.title",
        })}
        fixed={fixed}
      >
        {formatMessage({
          id: "sections.curriculum.header.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
      </Title>
      <Container>
        <ParagraphSection>
          {formatMessage({
            id: "sections.curriculum.sections.paragraph1",
          })
            .split("\n")
            .map((paragraph, key) => (
              <p key={key}>{paragraph}</p>
            ))}
        </ParagraphSection>
      </Container>
      <About
        fixed={computerProgramming.childImageSharp.fixed}
        reverse
        title={formatMessage({
          id: "sections.curriculum.sections.computerProgramming.title",
        })}
        paragraph={formatMessage({
          id: "sections.curriculum.sections.computerProgramming.paragraph",
        })}
      />
      <Features>
        <Feature
          Icon={FaRegKeyboard}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.imperative.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.imperative.paragraph",
          })}
        />
        <Feature
          Icon={BsCodeSlash}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.functional.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.functional.paragraph",
          })}
        />
        <Feature
          Icon={BsBraces}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.objectOriented.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.objectOriented.paragraph",
          })}
        />
        <Feature
          Icon={FaBrain}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.ai.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.ai.paragraph",
          })}
        />
        <Feature
          Icon={RiGamepadLine}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.graphics.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.graphics.paragraph",
          })}
        />
      </Features>
      <About
        fixed={innovation.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.curriculum.sections.innovation.title",
        })}
        paragraph={formatMessage({
          id: "sections.curriculum.sections.innovation.paragraph",
        })}
      />
      <Features>
        <Feature
          Icon={RiBrush3Line}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.creativity.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.creativity.paragraph",
          })}
        />
        <Feature
          Icon={BsCodeSlash}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.web.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.web.paragraph",
          })}
        />
        <Feature
          Icon={FaDocker}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.technology.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.technology.paragraph",
          })}
        />
      </Features>
      <About
        fixed={infrastructures.childImageSharp.fixed}
        reverse
        title={formatMessage({
          id: "sections.curriculum.sections.infrastructures.title",
        })}
        paragraph={formatMessage({
          id: "sections.curriculum.sections.infrastructures.paragraph",
        })}
      />
      <Features>
        <Feature
          Icon={RiComputerLine}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.system.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.system.paragraph",
          })}
        />
        <Feature
          Icon={FaNetworkWired}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.network.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.network.paragraph",
          })}
        />
        <Feature
          Icon={MdSecurity}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.security.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.security.paragraph",
          })}
        />
        <Feature
          Icon={FiDatabase}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.data.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.data.paragraph",
          })}
        />
        <Feature
          Icon={RiComputerLine}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.parallel.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.parallel.paragraph",
          })}
        />
        <Feature
          Icon={FiSettings}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.organization.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.organization.paragraph",
          })}
        />
        <Feature
          Icon={RiZoomInLine}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.rigor.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.rigor.paragraph",
          })}
        />
        <Feature
          Icon={RiBuildingLine}
          title={formatMessage({
            id: "sections.curriculum.sections.skills.company.title",
          })}
          paragraph={formatMessage({
            id: "sections.curriculum.sections.skills.company.paragraph",
          })}
        />
      </Features>
      <Container>
        <ParagraphSection>
          {formatMessage({
            id: "sections.curriculum.sections.paragraph2",
          })
            .split("\n")
            .map((paragraph, key) => (
              <p key={key}>{paragraph}</p>
            ))}
        </ParagraphSection>
      </Container>
    </DefaultLayout>
  )
}

export default TheCurriculum
