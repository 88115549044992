import { action } from "easy-peasy"

const ui = {
  drawerIsOpen: false,
  toggleDrawer: action((state, _payload) => {
    state.drawerIsOpen = !state.drawerIsOpen
  }),
}

export default ui
