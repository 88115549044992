import React from "react"
import styled from "styled-components"

import Container from "../Container"

import Divider from "../../assets/images/divider.svg"

const StyledFeatures = styled.div`
  background: #111;
  margin: 10rem 0;
  position: relative;
`

const DividerTop = styled(Divider)`
  position: absolute;
  width: 100%;
  left: 0;
  top: -1px;
  transform: rotate(180deg);
`

const DividerBottom = styled(Divider)`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
`

const FeaturesContent = styled.div`
  padding: 16rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Features = ({ children }) => {
  return (
    <StyledFeatures>
      <DividerTop />
      <Container>
        <FeaturesContent>{children}</FeaturesContent>
      </Container>
      <DividerBottom />
    </StyledFeatures>
  )
}

export default Features
