import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import CampusTitle from "../components/sections/Title"
import Container from "../components/Container"

const TitleSection = styled.div`
  margin: 10rem 0;
`

const EndSpace = styled.div`
  padding-bottom: 6rem;
`

const MailLink = styled.a`
  color: #a2de83;
`

const ApplyButton = styled.a`
  text-decoration: none;
  color: #111;
  background: #a3de83;
  padding: 2rem 3rem;
  display: block;
  text-align: center;
  border-radius: 0.5rem;
  transition: 150ms ease-in-out;
  &:hover {
    background: #111;
    color: #fff;
  }
`

const LegalNotice = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pool/banner.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <Seo
        title={formatMessage({
          id: "sections.legal.header.title",
        })}
      />
      <CampusTitle
        title={formatMessage({
          id: "sections.legal.header.title",
        })}
        fixed={fixed}
      >
        <p>
          {formatMessage({
            id: "sections.legal.header.paragraph",
          })}
        </p>
      </CampusTitle>
      <Container>
        <TitleSection>
          {formatMessage({
            id: "sections.legal.paragraph",
          })
            .split("\n")
            .map((paragraph, key) => (
              <p key={key}>{paragraph}</p>
            ))}
          <MailLink href="mailto:Loi09-08@1337.ma">Loi09-08@1337.ma</MailLink>
        </TitleSection>
        <ApplyButton href="https://candidature.1337.ma">
          {formatMessage({ id: `sections.legal.agree` })}
        </ApplyButton>
      </Container>
      <EndSpace />
    </DefaultLayout>
  )
}

export default LegalNotice
