import React, { Fragment } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { StyleSheetManager } from "styled-components"
import stylisRTLPlugin from "stylis-plugin-rtl"

const RTL = ({ children, isActive = true }) => {
  const intl = useIntl()

  return (
    <StyleSheetManager
      stylisPlugins={isActive ? intl.locale === "ar" && [stylisRTLPlugin] : []}
    >
      <Fragment>{children}</Fragment>
    </StyleSheetManager>
  )
}

export default RTL
