import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import Seo from "../components/Seo"

import DefaultLayout from "../layouts/default"
import CampusTitle from "../components/sections/Title"
import About from "../components/sections/About"
import SectionTitle from "../components/SectionTitle"
import Container from "../components/Container"

const TitleSection = styled.div`
  margin: 10rem 0;
  text-align: center;
`

const EndSpace = styled.div`
  padding-bottom: 6rem;
`

const ThePool = () => {
  const { formatMessage } = useIntl()
  const {
    image: {
      childImageSharp: { fixed },
    },
    after,
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pool/banner.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      after: file(relativePath: { eq: "pool/jump.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <DefaultLayout>
      <Seo
        title={formatMessage({
          id: "sections.thePool.header.title",
        })}
      />
      <CampusTitle
        title={formatMessage({
          id: "sections.thePool.header.title",
        })}
        fixed={fixed}
      />
      <Container>
        <TitleSection>
          <SectionTitle>
            {formatMessage({
              id: "sections.thePool.sections.immertion.title",
            })}
          </SectionTitle>
          <p>
            {formatMessage({
              id: "sections.thePool.sections.immertion.paragraph",
            })}
          </p>
        </TitleSection>
      </Container>
      <About
        fixed={after.childImageSharp.fixed}
        title={formatMessage({
          id: "sections.thePool.sections.jumpIn.title",
        })}
        paragraph={formatMessage({
          id: "sections.thePool.sections.jumpIn.paragraph",
        })
          .split("\n")
          .map((paragraph, key) => (
            <span key={key}>{paragraph}</span>
          ))}
      />
      <EndSpace />
    </DefaultLayout>
  )
}

export default ThePool
