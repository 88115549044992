import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"
import Particles from "react-particles-js"

import Divider from "../../assets/images/divider.svg"
import Container from "../Container"
import SectionTitle from "../SectionTitle"

const StyledCampusTitle = styled.div`
  color: #fff;
  padding: 20rem 0 24rem;
  position: relative;
  overflow: hidden;
  h2 {
    font-size: 3rem;
    margin: 0;
  }
`

const StyledDivider = styled(Divider)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const StyledImg = styled(Img)`
  position: absolute;
  top: -1px;
  left: 0;
`

const StyledParallax = styled(Parallax)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -2;
  .parallax-inner {
    height: 100%;
  }
`

const CampusTitle = ({ title, fixed, children }) => {
  return (
    <StyledCampusTitle>
      <ParallaxProvider>
        <Container>
          <SectionTitle dark notCenter>
            {title}
          </SectionTitle>
          {children}
        </Container>
        <Particles
          height="100%"
          width="100%"
          style={{
            position: "absolute",
            top: 0,
            zIndex: -1,
            background: "rgba(0,0,0, 0.75)",
          }}
          params={{
            particles: {
              number: {
                value: 37,
              },
              size: {
                value: 3,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
        />
        <StyledParallax y={["-80%", "30%"]}>
          <StyledImg
            fixed={fixed}
            style={{ width: "100%", height: "130%" }}
            draggable={false}
          />
        </StyledParallax>
        <StyledDivider />
      </ParallaxProvider>
    </StyledCampusTitle>
  )
}

export default CampusTitle
